/* .radiobtn{
    height: 85px;
    width: 30px;
    background-color: lightgray;
    border: black;
    margin-right: 70px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: self-end;
    border-radius: 10px;
} */
.radiobtn {
    width: 100%;
    border: 1px solid #f8f6fa;
    background-color: #f8f6fa;
    border-radius: 10px;
    padding: 10px;
    min-height: 100px;
    text-align: center;
}

.subCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 300px !important;
}

.nextbtn {
    display: flex;
    justify-content: right;
}

.form-control:disabled,
.form-control[readonly],
.form-control:disabled:hover {
    background-color: #e9ecef !important;
}

#squareIcon {
    height: 15px;
    width: 30px;
    border: 2px solid #5C068C;
    position: relative;
    top: 2px;
}

.card {
    border-radius: 10px !important;
}

.toast-container {
    right: 1rem;
    z-index: 1999;
}

/* #back{
    margin-right: 100px;
    padding: 5px 40px 5px 40px;
    background-color: black;
    color: white;
    border-radius: 2px;
} */
.customBorder {
    border: 2px solid #f8f6fa !important;
}

#resend {
    background-color: black;
    color: white;
    height: 33px;
}

.form-control-Synchrony {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;

    background-clip: padding-box;
    border: 1px solid #5C068C;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.text-violet {
    color: #5C068C !important;
}

#txtMobileNo:disabled {
    background-color: transparent !important;
}

.border-dark-Synchrony {
    border-color: #5C068C !important;
    /*box-shadow: 0 0 0 0.1rem #5C068C !important;*/
}

.form-control-Synchrony:focus {
    box-shadow: 0 0 0 0.1rem #5C068C !important;
}

.border-dark-error {
    border-color: #ff0000 !important;
}

.form-control:focus {
    box-shadow: 0 0 0 0.1rem #f8f6fa !important;
}

.bg-white {
    background-color: #FFFFFF !important;
}

.border-dark {
    border-color: #BFB8D3 !important;
}

.fee_box {
    height: 540px;
}

.lap_box {
    height: auto;
}

.table_box_right {
    margin-top: 13px;
}

/* .container_terms .left-col, .container_terms .right-col {
    height: auto !important;
} */

input[type=checkbox] {
    min-width: 20px !important;
    min-height: 20px !important;
    accent-color: #5C068C;
}

input[type=radio] {
    accent-color: #5C068C;
}

.form-select {
    display: block;
    height: 100%;
    border-radius: 5px;
    width: 100%;
    background-color: white !important;
    color: #5C068C !important;
}

.form-select-border {
    border-color: #BFB8D3 !important;
}

.form-select-Synchrony {
    display: block;
    height: 100%;
    border-radius: 5px;
    width: 100%;
    background-color: white !important;
    color: #5C068C !important;
}

.form-select-Synchrony-border {
    border-color: #5C068C !important;
    /*box-shadow: 0 0 0 0.1rem #5C068C !important;*/
}

button.bg-secondary:hover {
    background-color: #5C068C !important;
    color: #f8f6fa !important;
}

div.ShowContent {
    display: block;
}

div.HideContent {
    display: none;
}

.ShowContent {
    display: block;
}

.HideContent {
    display: none;
}

.col-100 {
    width: 100%;
    float: none;
    clear: both;
    display: block;
}

#customModel>.modal-content {
    width: 180% !important;
    left: -40% !important;
}

/* .center_right_col_table tr {
    border: none !important;
    border-top: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
    border-left: none !important;
    border-right: none !important;
} */

.container_terms .bottom_table_data {
    width: 100%;
    /* border: 1px solid #000; */
}

.container_terms .bottom_table_data td {
    /* width: 100%; */
    border: 1px solid #000;
}

.container_terms .left-col {
    padding-right: 5px;
}

.container_terms .left-col,
.container_terms .right-col {
    width: calc(50% - 5px);
    display: inline-block;
    height: 327px;
}

.container_terms .right-col table {
    padding: 0px;
    border-spacing: 0px;
    font-size: 10px;
    border: 1px solid #000;
}

.container_terms .left-col td,
.container_terms .right-col td {
    border: 1px solid #000;
    line-height: 14px;
    font-size: 12px;
}

.container_terms>p a {
    text-decoration: underline;
}

.container_terms .left-col,
.container_terms .right-col {
    width: calc(50% - 5px);
    display: inline-block;
    min-height: 200px;
}

.container_terms .left-col~.left-col,
.container_terms .right-col~.right-col {
    height: 133px;
}

.container_terms .left-col {
    padding-right: 5px;
}

.container_terms .left-col table,
.container_terms .right-col table {
    min-height: 100%;
}

.container_terms .left-col td,
.container_terms .right-col td {
    vertical-align: top;
}

.container_terms .left-col td:first-of-type,
.container_terms .right-col td:first-of-type {
    width: 90px;
    font-weight: normal;
}

.container_terms .two-col {
    padding: 5px 5px 5px 0;
    -webkit-columns: 2;
    -moz-columns: 2;
    -o-columns: 2;
    columns: 2;
}

.container_terms .two-col p {
    text-align: justify;
    margin: 0 0 5px;
}

.container_terms~p a {
    padding: 10px;
    display: block;
    width: 120px;
    margin: auto;
    color: #c42b26;
}

.react-datepicker__header {
    background-color: #f8f6fa !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: #5C068C !important;
    color: #f8f6fa !important;
    border-radius: 3px;
}

#disclosureHeading {
    display: none;
}

.tooltip>.tooltip-inner {
    min-width: 200%;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border: 1px solid #5C068C;
    /* background-color: #FFFFFF;
    color: #1b1b1b; */
}

.customborder-primary {
    border: 1px solid #5C068C !important;
}

@media (min-width : 1025px) {
    .customTooltip>.tooltip-inner {
        min-width: 200%;
        padding-top: .5rem;
        padding-bottom: .5rem;
        border: 1px solid #5C068C;
        /* background-color: #FFFFFF;
        color: #1b1b1b; */
    }
}

.captcha>span {
    font-family: 'Loved by the King', 'cursive' !important;
    background-image: linear-gradient(45deg, gray 5%, white 70%, gray);
    letter-spacing: 10px;
    border-radius: 5px;
}

.table_box {
    width: 325px;
    margin-top: 25px
}

@media (max-width: 767px) {
    #customModel>.modal-content {
        width: 100% !important;
        left: 0% !important;
    }

    .modal {
        padding-left: 0;
    }

    .container_terms .left-col,
    .container_terms .right-col {
        width: calc(50% - 5px);
        display: initial;
    }

    .left-col>.table {
        display: initial;
    }

    .tooltip>.tooltip-inner {
        min-width: min-content;
        overflow-x: hidden;
    }

    .fee_box {
        height: auto !important;
    }

    .table_box {
        width: auto;
        margin-top: 25px
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .lap_box {
        height: auto;
    }

    #customModel>.modal-content {
        width: 100% !important;
        left: 0% !important;
    }

    .apply_table {
        height: 750px;
    }

    .tooltip>.tooltip-inner {
        min-width: min-content;
        overflow-x: hidden;
    }

    .fee_box {
        height: 920px;
    }

    /* .fee_box {
        height: auto !important;
    } */

    .table_box {
        width: auto;
        margin-top: 25px
    }
}

@media print {
    .printThisPage {
        display: none;
    }

    .fee_box {
        margin-top: 170px !important;
        margin-bottom: 400px !important;
    }

    #disclosureHeading {
        display: block;
        margin-bottom: 1rem;
    }

    .container_terms .left-col td,
    .container_terms .right-col td {
        border: 1px solid #000;
        padding: 5px;
        line-height: 22px;
        font-size: 18px;
    }

    .table_box {
        width: 100%;
        min-height: fit-content !important;
    }
}

#congratulationModal>.modal-content>.modal-body {
    min-height: 450px;
    overflow: hidden !important;
}

.customMargin {
    margin-top: 12rem;
}

#oval {
    border-radius: 50%;
    background: linear-gradient(89.97deg, #5C068C 7.02%, #E5D7EC 92.7%);
    width: 900px;
    display: block;
    height: 400px;
    position: absolute;
    top: -50%;
    left: -7%;
}

.cancelButton {
    background-color: #E5D7EC !important;
    border: #5C068C;
}

#gemIcon {
    left: 45%;
    top: 12%;
    position: absolute;
}

#starIcon {
    left: 35%;
    top: 7%;
    transform: rotate(-30deg);
}

#startLifeIcon {
    left: 5%;
    top: 5%;
}

#squaresIcon {
    left: 29%;
    top: 24%;
    transform: rotate(-30deg);
}

#moonIcon {
    left: 20%;
    top: 10%;
    transform: rotate(-30deg);
}

#secStarIcon {
    left: 60%;
    top: 24%;
    transform: rotate(-30deg);
}

#secStartLifeIcon {
    left: 88%;
    top: 7%;
    transform: rotate(-30deg);
}

#secSquaresIcon {
    left: 68%;
    top: 7%;
    transform: rotate(-30deg);
}

#secMoonIcon {
    left: 78%;
    top: 21%;
    transform: rotate(-30deg);
}

@media (max-width : 576px) {
    #congratulationModal>.modal-content>.modal-body {
        min-height: 400px !important;
    }

    .customMargin {
        margin-top: 8rem !important;
    }

    #gemIcon {
        left: 42% !important;
    }

    #oval {
        width: 425px !important;
        height: 200px !important;
        top: -20% !important;
    }

    #starIcon {
        left: 34% !important;
        top: 4% !important;
    }

    #congratulationModal>.modal-content>.modal-body button {
        width: 100%
    }

    #gemIcon {
        left: 40% !important;
        top: 6% !important;
    }

    #starIcon {
        left: 22% !important;
        top: 4% !important;
    }

    #startLifeIcon {
        left: -6% !important;
    }

    #squaresIcon {
        left: 37% !important;
        top: 27% !important;
    }

    #secStarIcon {
        top: 26% !important;
    }

    #moonIcon {
        left: 15% !important;
        top: 19% !important;
    }

    #secStartLifeIcon {
        left: 90% !important;
        top: 4% !important;
        transform: rotate(-30deg);
    }

    #secSquaresIcon {
        left: 75% !important;
        top: 20% !important;
        transform: rotate(-30deg);
    }

    #secMoonIcon {
        left: 69% !important;
        top: 4% !important;
        transform: rotate(-180deg) !important;
    }
}

@media (min-width: 577px) and (max-width : 1023px) {
    .customMargin {
        margin-top: 9rem !important;
    }

    #gemIcon {
        left: 42% !important;
    }

    #oval {
        width: 570px !important;
        top: -56% !important;
    }

    #starIcon {
        left: 34% !important;
        top: 4% !important;
    }

}